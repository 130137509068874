<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Year" vid="application_year" :rules="{required: true, min: 4, max: 4}" v-slot="{ errors }">
              <b-form-group label-for="application_year">
                <template v-slot:label>
                  {{ $t('globalTrans.year') }}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  plain
                  @keypress="isNumber"
                  id="application_year"
                  v-model="data.application_year"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="area_type_id">
                    <template v-slot:label>
                      {{ $t('teaGardenConfig.area_type') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      :disabled="isGardenAdmin"
                      plain
                      v-model="data.area_type_id"
                      id="area_type_id"
                      :options="getAreaTypeList"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-show="ItemShow">
            <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="division_id">
                    <template v-slot:label>
                      {{ $t('teaGardenConfig.division') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      :disabled="isGardenAdmin"
                      plain
                      v-model="data.division_id"
                      id="division_id"
                      :options="divisionList"
                      :state="errors[0] ? false : (valid ? true : null)">
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-show="ItemShow">
            <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="district_id">
                    <template v-slot:label>
                      {{ $t('teaGardenConfig.district') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      :disabled="isGardenAdmin"
                      plain
                      v-model="data.district_id"
                      id="district_id"
                      :options="districtList"
                      :state="errors[0] ? false : (valid ? true : null)">
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-show="PauroshobaItemShow || UnionItemShow">
            <ValidationProvider name="Upazila" vid="upazila_id" v-slot="{ errors }">
                <b-form-group
                    label-for="upazila_id">
                    <template v-slot:label>
                      {{ $t('teaGardenConfig.upazila') }}
                    </template>
                    <b-form-select
                      plain
                      :disabled="isGardenAdmin"
                      v-model="data.upazila_id"
                      id="upazila_id"
                      :options="upazilaList"
                      :state="errors[0] ? false : (valid ? true : null)">
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-show="CityCorpItemShow">
            <ValidationProvider name="City Corporation" vid="city_corporation_id" v-slot="{ errors }">
              <b-form-group
                label-for="city_corporation_id">
                <template v-slot:label>
                  {{ $t('teaGardenConfig.city_corporation') }}
                </template>
                <b-form-select
                  plain
                  :disabled="isGardenAdmin"
                  v-model="data.city_corporation_id"
                  id="city_corporation_id"
                  :options="cityCorporationList"
                  :state="errors[0] ? false : (valid ? true : null)">
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Garden Name" vid="garden_id" rules="required|min_value:1">
                <b-form-group slot-scope="{ valid, errors }" label-for="garden_id">
                    <template v-slot:label>
                      {{ $t('teaGardenConfig.select_garden') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      :disabled="isGardenAdmin"
                      plain
                      v-model="data.garden_id"
                      :options="gardenList"
                      id="garden_id"
                      :state="errors[0] ? false : (valid ? true : null)">
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <slot v-if="processShow">
            <slot v-if="roundShow">
              <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="data.garden_id">
                <ValidationProvider name="Application phase" vid="round" rules="required" v-slot="{ errors }">
                  <b-form-group label-for="round">
                    <template v-slot:label>
                      {{ $t('teaGarden.application_phase') }}<span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      id="round"
                      v-model="data.round"
                      :options="applicationPhaseList"
                      disabled
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                      <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Previous Year Actual Production (Kg.)" vid="production_previous_year" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-for="production_previous_year">
                    <template v-slot:label>
                      {{ $t('teaGarden.previous_year_actual_production_kg') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="production_previous_year"
                      v-model="data.production_previous_year"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Estimated / Actual Production (Kg.)" vid="production_current_year" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="production_current_year">
                    <template v-slot:label>
                      {{ $t('teaGarden.current_year_production_kg') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="production_current_year"
                      v-model="data.production_current_year"
                      @input="getMaxPermissionQuantity(data.production_current_year)"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Permission for Tea Quantity Total Applied (Kg.)" vid="totalApplication_qty" :rules="'required|max_value:' + maxAppQty">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="totalApplication_qty">
                    <template v-slot:label>
                      {{ $t('teaGarden.total_permission_for_tea_quantity_applied_kg') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="totalApplication_qty"
                      v-model="data.totalApplication_qty"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider vid="prev_verified_qty">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="prev_verified_qty">
                    <template v-slot:label>
                      {{ $t('teaGarden.amount_tea_previously_approved') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      disabled
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="prev_verified_qty"
                      v-model="data.prev_verified_qty"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Permission for Tea Quantity Applied (Kg.)" vid="application_qty" :rules="'required|max_value:' + maxAppQty">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="application_qty">
                    <template v-slot:label>
                      {{ $t('teaGarden.permission_for_tea_quantity_applied_kg') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      disabled
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="application_qty"
                      v-model="data.application_qty"
                      @input="getTotalApplicationPrice()"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Average Auction Price (Tk.)" vid="avg_auction_price" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="avg_auction_price">
                    <template v-slot:label>
                      {{ $t('teaGarden.average_auction_price_tk') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="avg_auction_price"
                      v-model="data.avg_auction_price"
                      @input="getTotalApplicationPrice()"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider vid="total_value" v-slot="{ errors }">
                  <b-form-group
                    label-for="total_value">
                    <template v-slot:label>
                      {{ $t('teaGarden.total_value_of_tea_quantity_applied_tk') }}
                    </template>
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="total_value"
                      v-model="data.total_value"
                      disabled
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider vid="total_payable_cess" v-slot="{ errors }">
                  <b-form-group
                    label-for="total_payable_cess">
                    <template v-slot:label>
                      {{ $t('teaGarden.total_payable_cess_tk') }}
                    </template>
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="total_payable_cess"
                      v-model="data.total_payable_cess"
                      disabled
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- r_2_prev_current_production -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Return Two Prev/Current Production" vid="r_2_prev_current_production" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="r_2_prev_current_production">
                    <template v-slot:label>
                      {{ $t('teaGarden.r_2_prev_current_production') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="r_2_prev_current_production"
                      v-model="data.r_2_prev_current_production"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- broker_document -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Broker Document" vid="broker_document" :rules="fileRequired" v-slot="{ errors }">
                  <b-form-group
                    label-for="broker_document">
                    <template v-slot:label>
                      {{ $t('teaGarden.broker_document') }} <span class="text-danger">*</span>
                    </template>
                    <div class="d-flex">
                    <b-form-file
                      id="broker_document"
                      v-model="data.broker_document"
                      @change="onFileChange($event, 'broker_document')"
                      :state="errors[0] ? false : (valid ? true : null)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <a v-if="data.broker_document  && checkBase64(data.broker_document) === false" style = "margin-left: 8px;" class="btn-white btn-success p-1" target="_blank"
                      :href="teaGardenServiceBaseUrl + (isImage(data.broker_document) ? 'storage/' : '') + data.broker_document"><i class="ri-eye-line"></i></a>
                    </div>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- bank_agriment -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Bank Agriment" vid="bank_agriment" :rules="fileRequired" v-slot="{ errors }">
                  <b-form-group
                    label-for="bank_agriment">
                    <template v-slot:label>
                      {{ $t('teaGarden.bank_agriment') }} <span class="text-danger">*</span>
                    </template>
                    <div class="d-flex">
                    <b-form-file
                      id="bank_agriment"
                      v-model="data.bank_agriment"
                      @change="onFileChange($event, 'bank_agriment')"
                      :state="errors[0] ? false : (valid ? true : null)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <a v-if="data.bank_agriment && checkBase64(data.bank_agriment) === false" style = "margin-left: 8px;" class="btn-white btn-success p-1" target="_blank"
                      :href="teaGardenServiceBaseUrl + (isImage(data.bank_agriment) ? 'storage/' : '') + data.bank_agriment"><i class="ri-eye-line"></i></a>
                    </div>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- bank_noc -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Bank NOC Certificate" vid="bank_noc" :rules="fileRequired" v-slot="{ errors }">
                  <b-form-group
                    label-for="bank_noc">
                    <template v-slot:label>
                      {{ $t('teaGarden.bank_noc_certificate') }} <span class="text-danger">*</span>
                    </template>
                    <div class="d-flex">
                    <b-form-file
                      id="bank_noc"
                      v-model="data.bank_noc"
                      @change="onFileChange($event, 'bank_noc')"
                      :state="errors[0] ? false : (valid ? true : null)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <a v-if="data.bank_noc  && checkBase64(data.bank_noc) === false" style = "margin-left: 8px;" class="btn-white btn-success p-1" target="_blank"
                      :href="teaGardenServiceBaseUrl + (isImage(data.bank_noc) ? 'storage/' : '') + data.bank_noc"><i class="ri-eye-line"></i></a>
                    </div>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- vat_payment -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="VAT Payment Receipt" vid="vat_payment" :rules="fileRequired" v-slot="{ errors }">
                  <b-form-group
                    label-for="vat_payment">
                    <template v-slot:label>
                      {{ $t('teaGarden.vat_payment_receipt_previous') }} <span class="text-danger">*</span>
                    </template>
                    <div class="d-flex">
                    <b-form-file
                      id="vat_payment"
                      v-model="data.vat_payment"
                      @change="onFileChange($event, 'vat_payment')"
                      :state="errors[0] ? false : (valid ? true : null)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <a v-if="data.vat_payment && checkBase64(data.vat_payment) === false" style = "margin-left: 8px;" class="btn-white btn-success p-1" target="_blank"
                      :href="teaGardenServiceBaseUrl + (isImage(data.vat_payment) ? 'storage/' : '') + data.vat_payment"><i class="ri-eye-line"></i></a>
                    </div>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </slot>
          </slot>
        </b-row>
        <b-row class="text-right" v-if="processShow">
          <b-col v-if="roundShow">
            <b-button type="button" @click="saveUpdate(2)" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
            <b-button type="button" @click="saveUpdate(1)" variant="success" class="mr-2 btn-sm">{{ $t('teaGardenBtriService.submit_n_payment') }}</b-button>
            <!-- <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button> -->
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
        <b-row v-if="processShowR">
          <b-col class="text-center">
            <h5 class="text-danger">{{ $t('teaGarden.ex_garden_pending_message') }}</h5>
          </b-col>
        </b-row>
        <b-row v-if="roundShowR">
          <b-col class="text-center">
            <h5 class="text-danger">{{ $t('teaGarden.ex_garden_max_app_message') }}</h5>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { authServiceBaseUrl, teaGardenServiceBaseUrl } from '@/config/api_config'
import { exGardenAppStore, exGardenAppUpdate, exGardenData } from '../../../api/routes'
import { helpers } from '@/utils/helper-functions'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
  data () {
    return {
      ItemShow: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      CityCorpItemShow: false,
      PauroshobaItemShow: false,
      UnionItemShow: false,
      processShow: true,
      processShowR: false,
      roundShow: true,
      roundShowR: false,
      valid: null,
      conditionStatus: '',
      fileRequired: 'required',
      isGardenAdmin: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      office_id: 0,
      data: {
        application_year: 0,
        round: 1,
        area_type_id: 0,
        division_id: 0,
        district_id: 0,
        city_corporation_id: 0,
        upazila_id: 0,
        garden_id: 0,
        production_previous_year: '',
        production_current_year: '',
        totalApplication_qty: '',
        prev_verified_qty: 0,
        avg_auction_price: '',
        application_qty: '',
        total_payable_cess: '',
        total_value: '',
        bank_noc: '',
        vat_payment: ''
      },
      gardenList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      cityCorporationList: [],
      municipalityList: [],
      maxAppQty: 0
    }
  },
  created () {
    if (this.id) {
      this.fileRequired = ''
      const tmp = this.exGardenAppList()
      this.data.oldBankNoc = tmp.bank_noc
      this.data.oldVatPament = tmp.vat_payment
      this.data.oldBankAgriment = tmp.bank_agriment
      this.data.oldBrokerDocument = tmp.broker_document
      this.data.prev_verified_qty = tmp.prev_verified_qty
      this.data.application_qty = tmp.application_qty
      this.data = Object.assign({}, this.data, tmp)
      this.getMaxPermissionQuantity(tmp.production_current_year)
      this.getTotalApplicationPrice()
      // this.getTotalPrice()
    }
    if (this.isGardenAdminCheckGardenId()) {
      this.isGardenAdmin = true
      this.data.garden_id = this.isGardenAdminCheckGardenId()
      const gardenData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === this.isGardenAdminCheckGardenId())
      this.data.area_type_id = gardenData.area_type_id
      this.data.division_id = gardenData.division_id
      this.data.district_id = gardenData.district_id
      this.data.city_corporation_id = gardenData.city_corporation_id
      this.data.upazila_id = gardenData.upazila_id
    }
    if (this.id || this.isGardenAdminCheckGardenId()) {
      if (this.data.area_type_id === 1) {
        this.ItemShow = true
        this.CityCorpItemShow = true
        this.PauroshobaItemShow = false
        this.UnionItemShow = false
      } else if (this.data.area_type_id === 2) {
        this.ItemShow = true
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = true
        this.UnionItemShow = false
      } else if (this.data.area_type_id === 3) {
        this.ItemShow = true
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = false
        this.UnionItemShow = true
      }
    }
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    this.data.application_year = currentYear
  },
  updated () {
    // this.data.application_qty = this.data.totalApplication_qty - this.data.prev_verified_qty
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    unitList () {
      return this.$store.state.TeaGardenService.commonObj.unitList
    },
    divisionList () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    },
    getAreaTypeList: function () {
      const objectData = this.$store.state.commonObj.areaTypeList
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    getOfficeId: function () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === this.data.garden_id)
    },
    applicationPhaseList: function () {
      return this.$store.state.TeaGardenService.commonObj.applicationPhaseList
    },
    yearList () {
      return helpers.getYearList()
    }
  },
  watch: {
    'data.area_type_id': function (newVal, oldVal) {
      this.gardenList = this.getAreaTypeWiseGardenList(newVal)
      this.ItemShow = true
      if (newVal === 1) {
        this.CityCorpItemShow = true
        this.PauroshobaItemShow = false
        this.UnionItemShow = false
      } else if (newVal === 2) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = true
        this.UnionItemShow = false
      } else if (newVal === 3) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = false
        this.UnionItemShow = true
      }
    },
    'data.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
      this.gardenList = this.getDivisionWiseGardenList(newVal)
    },
    'data.district_id': function (newVal, oldVal) {
      this.cityCorporationList = this.getCityCorporationList(newVal)
      this.upazilaList = this.getUpazilaList(newVal)
      this.gardenList = this.getDistrictWiseGardenList(newVal)
    },
    'data.upazila_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.municipalityList = this.getPauroshobaList(newVal)
      this.gardenList = this.getUpazilaWiseGardenList(newVal)
    },
    'data.city_corporation_id': function (newVal, oldVal) {
      this.gardenList = this.getCityCorporationWiseGardenList(newVal)
    },
    'data.garden_id': function (newVal, oldVal) {
      this.getOfficeUser(newVal)
      if (!this.id) {
        this.getGardenData(newVal)
      }
    },
    'data.totalApplication_qty': function (newVal, oldVal) {
      this.getTotalPrice(newVal)
      this.getTotalApplicationPrice(newVal)
    }
  },
  methods: {
    async getGardenData () {
      const params = {
        application_year: this.data.application_year,
        garden_id: this.data.garden_id
      }
      const result = await RestApi.getData(teaGardenServiceBaseUrl, exGardenData, params)
      if (result.success) {
        this.data.prev_verified_qty = result.data.total_info.verified_qty
        this.data.round = result.data.total_info.total_app + 1
        if (result.data.total_info.total_app === 7) {
          this.roundShow = false
          this.roundShowR = true
        }
        if (result.data.latest_app_status && result.data.latest_app_status <= 5) {
          this.processShow = false
          this.processShowR = true
        } else {
          this.processShow = true
          this.processShowR = false
        }
      }
    },
    getAreaTypeData (typeId) {
      this.ItemShow = true
      if (typeId === 1) {
        this.CityCorpItemShow = true
        this.PauroshobaItemShow = false
        this.UnionItemShow = false
      } else if (typeId === 2) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = true
        this.UnionItemShow = false
      } else if (typeId === 3) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = false
        this.UnionItemShow = true
      }
    },
    async getOfficeUser (id) {
      const gardenData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
      this.data.area_type_id = gardenData.area_type_id
      this.data.division_id = gardenData.division_id
      this.data.district_id = gardenData.district_id
      this.data.upazila_id = gardenData.upazila_id
      this.data.city_corporation_id = gardenData.city_corporation_id
      this.office_id = gardenData.office_id
      const params = {
        office_id: this.office_id
      }
      const result = await RestApi.getData(authServiceBaseUrl, '/user-management/user/office-user', params)
      if (result.success) {
        this.data.user_id = result.data.value
        this.data.applicant_name_en = result.data.text_en
        this.data.applicant_name_bn = result.data.text_bn
      }
    },
    getMaxPermissionQuantity (value) {
      let totalPermissionQuantity = 0
      if (this.data.round === 1) {
        totalPermissionQuantity = (value * 15) / 100
      } else {
        totalPermissionQuantity = (value * 25) / 100
      }
      // if (!this.id) {
      //   this.data.totalApplication_qty = totalPermissionQuantity
      // } else {
      //   this.data.totalApplication_qty = this.data.application_qty + this.data.prev_verified_qty
      // }
      this.data.totalApplication_qty = totalPermissionQuantity
      this.maxAppQty = totalPermissionQuantity
    },
    getTotalPrice () {
      if (this.data.totalApplication_qty > this.maxAppQty) {
        this.$nextTick(() => {
          this.data.totalApplication_qty = this.maxAppQty
          this.data.application_qty = this.data.totalApplication_qty - this.data.prev_verified_qty
        })
      } else {
        // if (!this.id) {
          this.data.application_qty = this.data.totalApplication_qty - this.data.prev_verified_qty
        // }
      }
    },
    getTotalApplicationPrice () {
      let totalPrice = 0
      let totalCss = 0
      totalPrice = this.data.application_qty * this.data.avg_auction_price
      this.data.total_value = totalPrice
      totalCss = totalPrice / 100
      this.data.total_payable_cess = totalCss
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === id)
    },
    getUpazilaList (id) {
      return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
    },
    getUnionList (upazilaId) {
       return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1 && item.upazila_id === upazilaId)
    },
    getCityCorporationList (districtId) {
      const objectData = this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === districtId)
      return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    getPauroshobaList (upazillaId = null) {
      const objectData = this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.upazila_id === upazillaId)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    getAreaTypeWiseGardenList (id) {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.area_type_id === id)
    },
    getDivisionWiseGardenList (id) {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.division_id === id)
    },
    getDistrictWiseGardenList (id) {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.district_id === id)
    },
    getUpazilaWiseGardenList (id) {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.upazila_id === id)
    },
    getCityCorporationWiseGardenList (id) {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.city_corporation_id === id)
    },
    onFileChange (event, property) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.data[property] = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.data[property] = ''
        }
    },
    async saveUpdate (status = 0) {
      var check = await this.$refs.form.validate()
      if (check) {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        this.data.status = status
        if (this.id) {
          result = await RestApi.putData(teaGardenServiceBaseUrl, `${exGardenAppUpdate}/${this.id}`, this.data)
        } else {
          result = await RestApi.postData(teaGardenServiceBaseUrl, exGardenAppStore, this.data)
        }

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })

          this.$bvModal.hide('modal-form')
          if (result.is_payment) {
              const baseTableId = result.base_table_id
              this.$router.push({ name: 'tea_garden_service.btb.payment', params: { id: baseTableId }, query: { from: 'tea_garden_service.garden.ex_garden_online_application' } })
          } else {
              this.$router.push({ name: 'tea_garden_service.garden.ex_garden_online_application' })
          }
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    exGardenAppList () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    checkBase64 (string) {
      var result = ''
      result = string.match('data:')
      if (result) {
          return true
      } else {
          return false
      }
    }
  }
}
</script>
