<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <b-table-simple hover bordered small caption-top responsive>
                              <b-tbody>
                                <b-tr>
                                  <b-td><strong>{{ $t('globalTrans.organization') }}</strong></b-td>
                                  <b-td class="text-left">
                                    {{ currentLocale === 'en' ? 'Bangladesh Tea Board' : 'বাংলাদেশ চা বোর্ড' }}
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td><strong>{{ $t('teaGardenConfig.service_name') }}</strong></b-td>
                                  <b-td class="text-left">
                                    {{ currentLocale === 'en' ? data.service.service_name_en : data.service.service_name_bn }}
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td><strong>{{ $t('teaGardenService.application_id') }}</strong></b-td>
                                  <b-td class="text-left">
                                    {{ data.app_id }}
                                  </b-td>
                                </b-tr>
                                <b-tr v-if="data.payment.payment_type === 1">
                                  <b-td><strong>{{ $t('teaGardenBtriService.receipt') }}</strong></b-td>
                                  <b-td class="text-left">
                                    {{ data.payment.received_number }}
                                  </b-td>
                                </b-tr>
                                <b-tr v-else-if="data.payment.payment_type === 2">
                                  <b-td><strong>{{ $t('teaGardenBtriService.check_no') }}</strong></b-td>
                                  <b-td class="text-left">
                                    {{ data.payment.bank_slip_serial_no }}
                                  </b-td>
                                </b-tr>
                                <b-tr v-else>
                                  <b-td><strong>{{ $t('teaGardenBtriService.transaction_number') }}</strong></b-td>
                                  <b-td class="text-left">
                                    {{ data.payment.transaction_number }}
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td><strong>{{ $t('teaGardenBtriService.transaction_date') }}</strong></b-td>
                                  <b-td class="text-left">
                                    {{ data.payment.date | dateFormat }}
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td><strong>{{ $t('globalTrans.payment_type') }}</strong></b-td>
                                  <b-td class="text-left">
                                    {{ getPaymentTypeName(data.payment.payment_type) }}
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td><strong>{{ $t('priceMonitoring.amount_in_taka') }}</strong></b-td>
                                  <b-td class="text-left">
                                    {{ $n(data.payment.amount) }} /-
                                  </b-td>
                                </b-tr>
                              </b-tbody>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { teaGardenServiceBaseUrl } from '@/config/api_config'
// import { applicationReturn } from '../../api/routes'
import ExportPdf from './payment-info-pdf.js'

export default {
    name: 'FormLayout',
    props: ['item', 'id'],
    created () {
      if (this.item) {
        this.data = this.item
      }
    },
    data () {
      return {
        data: {
        }
      }
    },
    computed: {
      loading () {
        return this.$store.state.commonObj.loading
      },
      btriPaymentType () {
        return this.$store.state.TeaGardenService.commonObj.btriPaymentType
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
      pdfExport () {
        const reportTitle = this.$t('externalLrcpn.payment_receipt')
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 18)
        let queryParams = null
        if (service !== undefined) {
          queryParams = {}
          if (service.office_type_id) {
            queryParams.office_type_id = service.office_type_id
          }
          if (service.office_id) {
            queryParams.office_id = service.office_id
          }
        }
        ExportPdf.exportPdfDetails(teaGardenServiceBaseUrl, '/configuration/report-heading/detail', 5, reportTitle, this, queryParams)
      },
      getPaymentTypeName (id) {
        const obj = this.btriPaymentType.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    }
}
</script>
